import { useEffect, useState } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { UserProfile } from '@sunrun/sales-experience-shared';
import { AmplifyGet } from '@sunrun/sales-experience-shared/amplify-api-wrapper';
import { useHybridToken } from './Auth';
import { logger } from './logger';

const useUserProfile = singletonHook(undefined, (): UserProfile | undefined => {
  const [userProfile, setUserProfile] = useState<UserProfile>();
  const { hybridToken } = useHybridToken();
  useEffect(() => {
    if (hybridToken) {
      AmplifyGet<{ userProfile: UserProfile }>(
        'Get User Profile',
        'SalesExpDashboardApi',
        '/userProfile',
        {
          headers: {
            Authorization: `Bearer ${hybridToken}`,
          },
        },
        logger,
      )
        .then((response) => {
          setUserProfile(
            response.userProfile.sfdcContact ?? response.userProfile,
          );
        })
        .catch(() => {
          // TODO: Fix under SPLAT1-7040
          // Do nothing
        });
    }
  }, [hybridToken]);
  return userProfile;
});

export { useUserProfile };
