import styled from '@emotion/styled/macro';
import { useEffect, useState } from 'react';
import {
  getSplatUserType,
  isRetailUser,
  isSunrunManaged,
  UserProfile,
} from '@sunrun/sales-experience-shared';
import {
  LoadingIndicator,
  SalesToolsWarning,
} from '@sunrun/experience-ui-components';
import { GreetingHeader } from 'components/organisms/GreetingHeader';
import { MetricsGrid } from 'components/organisms/MetricsGrid';
import { QuickLinks } from 'components/organisms/QuickLinks';
import { DashboardMetric, getMetrics } from 'providers/Metrics';
import { useHybridToken } from 'providers/Auth';

const Overview = () => {
  const [metrics, setMetrics] = useState(
    new Array(5).fill({
      icon: null,
      label: null,
      href: null,
      value: null,
    }) as DashboardMetric,
  );
  const [userProfile, setUserProfile] = useState({} as UserProfile);
  const { hybridToken } = useHybridToken();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (hybridToken) {
      setLoading(true);
      getMetrics(hybridToken)
        .then((res) => {
          setUserProfile(res.userProfile);
          setMetrics(res.metrics);
        })
        .catch(() => {
          // TODO: Fix under SPLAT1-7039
          // Do nothing
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [hybridToken]);

  const now = new window.Date();
  const splatUserType = getSplatUserType(userProfile);
  const isChannelPartner = splatUserType === 'Integrated Partner';
  const isCEA = isRetailUser(splatUserType);
  const isSRM = isSunrunManaged(userProfile);
  const isD2H = splatUserType === 'Direct to Home';
  const isFusion = userProfile.partnerName === 'Fusion';
  const isFusionAthlete = splatUserType === 'Fusion Setter';
  const isMetricsVisible =
    JSON.stringify(userProfile) !== '{}' &&
    !isChannelPartner &&
    !isCEA &&
    !isFusionAthlete;

  const shouldHideWarning =
    !userProfile?.title?.toLocaleLowerCase().includes('athlete') &&
    userProfile?.splatRole !== 'Retail Advisor' &&
    userProfile?.sellsSunrun?.toLocaleLowerCase() === 'no'
      ? 'No'
      : 'Yes';

  return (
    <Container loading={+loading}>
      <Dashboard>
        {loading ? (
          <LoadingIndicator className="dashboard-overview-loader" />
        ) : (
          <>
            <SalesToolsWarning sellsSunrun={shouldHideWarning} />
            <GreetingHeader
              userProfile={userProfile}
              date={now}></GreetingHeader>
            <DashboardValues>
              {isMetricsVisible && (
                <MetricsGrid metrics={metrics}></MetricsGrid>
              )}
              <QuickLinks
                isChannelPartner={isChannelPartner}
                isCEA={isCEA}
                isD2H={isD2H}
                isFusion={isFusion}
                isSRM={isSRM}
              />
            </DashboardValues>
          </>
        )}
      </Dashboard>
    </Container>
  );
};

const Container = styled.div<{ loading: number }>`
  scrollbar-gutter: 16px;
  padding: 32px;
  display: flex;
  justify-content: ${(props) => (props.loading ? 'center' : 'flex-start')};
  @media (max-width: 880px) {
    justify-content: center;
  }
`;
const DashboardValues = styled.div`
  display: flex;
  gap: 32px;
  @media (max-width: 880px) {
    flex-direction: column;
  }
`;
const Dashboard = styled.div``;

export { Overview };
