import styled from '@emotion/styled/macro';
import { Typography } from '@sunrun/experience-ui-components';
import { UserProfile } from '@sunrun/sales-experience-shared';

type props = { userProfile: UserProfile };

const GreetingMessage = ({ userProfile }: props) => {
  return (
    <Container aria-label="greeting">
      <UpperPrompt>
        <Typography color="#000000" variant="h1">
          Hello, {userProfile.firstName}
          {userProfile.firstName ? '!' : ''}
        </Typography>
      </UpperPrompt>
      <LowerPrompt>
        <Typography color="#6f6d68" variant="h1">
          Today is looking good.
        </Typography>
      </LowerPrompt>
    </Container>
  );
};

const Container = styled.div``;
const UpperPrompt = styled.div``;
const LowerPrompt = styled.div``;

export { GreetingMessage };
