const formatCurrency = (value?: number | null, decimals = 0) => {
  if (value === null || value === undefined) {
    return 'N/A';
  }
  const currencyValue = Number(value).toFixed(decimals);
  const negative = currencyValue.startsWith('-') ? '-' : '';
  return `${negative}$${currencyValue
    .replace(/^(?:-)/, '')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
};

export { formatCurrency };
