import { useEffect } from 'react';

type Source = 'Dashboard';

// Messages
type MessageCreateLead = {
  type: 'COMMAND:CREATE_LEAD';
  source: Source;
};
type MessageCommandUrlChange = {
  type: 'COMMAND:CHANGE_URL';
  source: Source;
  payload: {
    url: string;
  };
};

type MessageQueryAuthToken = {
  type: 'QUERY:AUTH_TOKEN';
  source: Source;
};
type MessageOnAuthToken = {
  type: 'EVENT:AUTH_TOKEN';
  source: Source;
  payload: {
    token: string;
    tokenType: string;
  };
};

type Message =
  | MessageCreateLead
  | MessageCommandUrlChange
  | MessageQueryAuthToken
  | MessageOnAuthToken;

type Subscriber = {
  id: string;
  action: (action: Message) => void;
};

let subscribers: Subscriber[] = [];

const subscribe = (action: Subscriber['action']) => {
  const id = Math.random().toString(36);
  subscribers.push({ id, action });
  return () => {
    subscribers = subscribers.filter((subscriber) => subscriber.id !== id);
  };
};

const useSubscribe = (action: Subscriber['action']) => {
  useEffect(() => {
    subscribe(action);
  }, [action]);
};

window.addEventListener<Message | any>('message', (event) => {
  if (event.origin === window.origin) {
    return;
  }
  const message: Message = event.data;
  subscribers.forEach((subscriber) => subscriber.action(message));
});

const publishMessage = (message: Message) => {
  const event = message;
  window.parent.postMessage(event, '*');
};

const publish = {
  queryAuthToken: () => {
    const message: MessageQueryAuthToken = {
      source: 'Dashboard',
      type: 'QUERY:AUTH_TOKEN',
    };
    publishMessage(message);
  },
  createLead: () => {
    const message: MessageCreateLead = {
      source: 'Dashboard',
      type: 'COMMAND:CREATE_LEAD',
    };
    publishMessage(message);
  },
  openTerros: () => {
    const message: MessageCommandUrlChange = {
      source: 'Dashboard',
      type: 'COMMAND:CHANGE_URL',
      payload: {
        url: 'https://app.terros.com',
      },
    };
    publishMessage(message);
  },
};

export { useSubscribe, publish };
