import styled from '@emotion/styled/macro';
import { SvgIcon, SvgNames } from '@sunrun/experience-ui-components';

type props = {
  text: string;
  onClick?: () => void;
  href?: string;
  target?: string;
};

const QuickLink = ({
  text,
  onClick = () => {},
  href = '',
  target = '',
}: props) => {
  const asset = href === '#lead' ? 'plus' : 'arrow-square-out';
  return (
    <Container onClick={onClick} href={href} target={target}>
      <Text>{text}</Text>
      <SvgIcon
        height={24}
        width={24}
        name={asset as SvgNames}
        color="rgba(0,0,0,0.8)"
      />
    </Container>
  );
};

const Container = styled.a`
  display: flex;
  font-family: 'Roobert', sans-serif;
  flex-direction: row;
  align-items: center;
  padding: 18px 24px 18px 24px;
  gap: 18px;
  height: 30px;
  border: 0.5px solid #6f6d68;
  border-radius: 800px;
  outline: none;
  min-width: max-content;
  text-decoration: none;
  transition: 0.15s;
  background: rgba(251, 247, 236, 0.1);
  @media (max-width: 880px) {
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    height: 60px;
  }
  &:focus-visible {
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.1);
    background: rgba(255, 251, 240, 0.1);
  }
  &:hover {
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.1);
    background: rgba(255, 251, 240, 0.1);
  }
`;

const Text = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #181e3a;
  @media (min-width: 1600px) {
    font-size: 18px;
  }
  @media (min-width: 1920px) {
    font-size: 24px;
  }
`;

export { QuickLink };
