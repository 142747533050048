import styled from '@emotion/styled/macro';
import { UserProfile } from '@sunrun/sales-experience-shared';
import { GreetingMessage } from 'components/molecules/GreetingMessage';
import { QuarterDate } from 'components/molecules/QuarterDate';

type props = { userProfile: UserProfile; date: Date };

const GreetingHeader = ({ userProfile, date }: props) => {
  return (
    <Container>
      <GreetingMessage userProfile={userProfile}></GreetingMessage>
      <QuarterDate date={date}></QuarterDate>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;
`;

export { GreetingHeader };
