import styled from '@emotion/styled/macro';

type props = { date: Date };

const QuarterDate = ({ date }: props) => {
  const currentQuarter = Math.floor(date.getMonth() / 3) + 1;
  const currentYear = date.getFullYear();

  const monthRanges = [
    ['January', 'March'],
    ['April', 'June'],
    ['July', 'September'],
    ['October', 'December'],
  ];
  const startMonth = monthRanges[currentQuarter - 1][0];
  const endMonth = monthRanges[currentQuarter - 1][1];

  return (
    <Container aria-label="quarter">
      <Quarter>Quarter {currentQuarter}</Quarter>
      <Date>
        {startMonth}, {currentYear} - {endMonth}, {currentYear}
      </Date>
    </Container>
  );
};

const Container = styled.div``;
const Quarter = styled.div`
  color: #000000;
`;
const Date = styled.div`
  color: #6f6d68;
`;

export { QuarterDate };
