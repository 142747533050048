import { css, Global } from '@emotion/react';
import { FeatureFlagProvider } from '@sunrun/sales-experience-shared/react-feature-flags';
import styled from '@emotion/styled/macro';
import { RouterProvider } from 'react-router-dom';
import { tokens } from '@sunrun/experience-ui-components';
import { router } from 'router';
import { useUserProfile } from 'providers/UserProfile';

const App = () => {
  const userProfile = useUserProfile();
  return (
    <Container>
      <Global
        styles={css`
          @import url('https://fonts.cdnfonts.com/css/roobert');

          body {
            background: ${tokens.BACKGROUND_LIGHT};
            margin: 0px;
            padding: 0px;
            box-sizing: border-box;
            min-height: 100vh;
          }
        `}
      />
      <FeatureFlagProvider identity={userProfile?.contactId}>
        <RouterProvider router={router} />
      </FeatureFlagProvider>
    </Container>
  );
};

const Container = styled.div`
  font-family: 'Roobert', sans-serif;
`;

export { App };
