import styled from '@emotion/styled/macro';
import {
  Button,
  SvgIcon,
  SvgNames,
  Tooltip,
  Typography,
  tokens,
} from '@sunrun/experience-ui-components';
import { useMemo, useRef, useState } from 'react';
import { PipelineCard } from '../molecules/PipelineCard';
import { PipelineProspect } from 'providers/Pipeline';

const PIPELINE_COLUMN_WIDTH = 321;

const PipelineColumn = ({
  stage,
  data,
  allowExpand,
  includeSalesRep,
  useLeftMenu,
}: {
  stage: string;
  data: PipelineProspect[];
  allowExpand: boolean;
  includeSalesRep: boolean;
  useLeftMenu: boolean;
}) => {
  const [expanded, setExpanded] = useState(false);
  const columnRef = useRef<HTMLDivElement>(null);
  const { allowJobCode, tooltip, tooltipTitle } = useMemo(() => {
    let allowJobCode = false;
    let tooltipTitle = '';
    let tooltip = '';
    switch (stage) {
      case 'New Customer':
        tooltipTitle = 'Leads & Opportunities';
        tooltip =
          'This column shows customers in the lead or opportunity stage who do not yet have an offer generated.';
        break;
      case 'Offer Created':
        tooltipTitle = 'Opportunities with Offers';
        tooltip =
          "This column customers who have an opportunity and an offer generated, who haven't yet signed any offer. ";
        break;
      case 'Checkout':
        tooltipTitle = 'Signed Opportunities';
        tooltip =
          'This column customers who have at least one signed offer (Opportunity stage 4. Signed thru 6. Submitted) and have not yet reached Sunrun Approval (Opportunity stage 7. Closed Won)';
        break;
      case 'Home Review':
        tooltipTitle = 'Sunrun Approved and Site Survey';
        tooltip =
          'This column shows customers in stages: Sunrun approval (Opportunity Stage 7), home review scheduled, and home review complete.';
        allowJobCode = true;
        break;
      case 'Design Approval':
        tooltipTitle = 'CAP Process';
        tooltip =
          'This column shows customers with project tasks: Layout + SOW Reveiw, Complete CAP Package, and Complete Design Package.';
        allowJobCode = true;
        break;
      case 'City Permitting':
        tooltipTitle = 'Building Permits';
        tooltip =
          'This column shows customers with project tasks: Submit Building Permit Application and Recieve and Process Building Permit';
        allowJobCode = true;
        break;
      case 'Install Prep':
        tooltipTitle = 'Schedule Installation';
        tooltip =
          'This column shows customers with project task: Schedule Installation.';
        allowJobCode = true;
        break;
      case 'Installation':
        tooltipTitle = 'Installation Progress';
        tooltip =
          'This column shows customers with project tasks: Start Installation and Complete Installation';
        allowJobCode = true;
        break;
      case 'City Inspection':
        tooltipTitle = 'Building Permit';
        tooltip =
          'This column shows customers with project task: Record Final Inspection Signoff (ESO).';
        allowJobCode = true;
        break;
      case 'Power On':
        tooltipTitle = 'PTO & System Activation';
        tooltip =
          'This column shows customers with project tasks: Activation Call and Confirm System Production.';
        allowJobCode = true;
        break;
      case 'ARC':
        tooltipTitle = 'PTO, Closed Lost, & Cancelled';
        tooltip =
          'This column shows customers with: an open At Risk Customer case, in Opportunity stage 11. Closed Lost, and Opportunity Stage 9. Cancelled.';
        allowJobCode = true;
        break;
      case 'UNKNOWN':
        tooltipTitle = 'Unknown';
        tooltip =
          "This column shows customers who don't seem to fit into any other category.";
        allowJobCode = true;
        break;
      default:
        allowJobCode = true;
        break;
    }

    return {
      allowJobCode,
      tooltip,
      tooltipTitle,
    };
  }, [stage]);

  return (
    <Column expanded={expanded} ref={columnRef}>
      <ColumnHeader>
        <ColumnHeaderArea>
          <Typography>{stage}</Typography>
          {expanded && (
            <Tooltip
              content={
                <TooltipContainer>
                  <Typography size={20} fontWeight="bold">
                    {tooltipTitle}
                  </Typography>
                  <Typography>{tooltip}</Typography>
                </TooltipContainer>
              }
              direction="bottom-left">
              <SvgIcon
                name={SvgNames.InfoOutline}
                width={24}
                height={24}
                color={tokens.HEROBLUE_50}
              />
            </Tooltip>
          )}
        </ColumnHeaderArea>
        <ColumnHeaderArea>
          {data?.length > 0 && <Count>{data?.length}</Count>}
          {allowExpand && (
            <ExpandButton
              iconMiddle={
                <SvgIcon
                  name={
                    expanded
                      ? SvgNames.ArrowsInSimple
                      : SvgNames.ArrowsOutSimple
                  }></SvgIcon>
              }
              color="tertiary"
              onClick={() => {
                setExpanded((value) => !value);
                if (columnRef.current) {
                  columnRef.current.scrollIntoView({
                    block: 'nearest',
                    inline: 'start',
                  });
                }
              }}></ExpandButton>
          )}
        </ColumnHeaderArea>
      </ColumnHeader>
      <ColumnBody>
        {data?.map((pipelineProspect) => (
          <PipelineCard
            key={pipelineProspect.key}
            pipelineProspect={pipelineProspect}
            includeSalesRep={includeSalesRep}
            useLeftMenu={useLeftMenu}
            allowJobCode={allowJobCode}></PipelineCard>
        ))}
      </ColumnBody>
    </Column>
  );
};

const Column = styled.div<{ expanded: boolean }>`
  --numberOfColumns: calc((100vw - 95px) / ${PIPELINE_COLUMN_WIDTH});
  --calculatedSize: calc(
    round(down, var(--numberOfColumns), 1px) * ${PIPELINE_COLUMN_WIDTH}
  );
  flex: ${(props) =>
    props.expanded
      ? '0 0 var(--calculatedSize)'
      : `0 0 ${PIPELINE_COLUMN_WIDTH}px`};
  width: ${(props) =>
    props.expanded ? 'var(--calculatedSize)' : `${PIPELINE_COLUMN_WIDTH}px`};
  display: grid;
  grid-template-rows: auto 1fr;
  background-color: #1f26470a;
  padding: 0.75rem;
  border-radius: 10px;
  scroll-snap-align: start;
  scroll-snap-stop: always;
`;

const ColumnHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5rem;
`;

const ColumnHeaderArea = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const TooltipContainer = styled.div`
  width: 400px;
`;

const Count = styled.div`
  border-radius: 100px;
  border: 1px solid ${tokens.OFFWHITE_40};
  padding: 0 0.5rem;
  background: ${tokens.WHITE};
`;

const ExpandButton = styled(Button)`
  padding: 0.5rem;
`;

const ColumnBody = styled.div`
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  gap: 1rem;
`;

export { PIPELINE_COLUMN_WIDTH, PipelineColumn };
