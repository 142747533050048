import { useState } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { useSubscribe, publish } from 'helpers/messaging';

const hybridTokenCache = '';
const hybridTokenTypeCache = '';

const useHybridToken = singletonHook(
  { hybridToken: '', hybridTokenType: '' },
  () => {
    const [hybridToken, setHybridToken] = useState(hybridTokenCache);
    const [hybridTokenType, setHybridTokenType] =
      useState(hybridTokenTypeCache);

    if (!hybridToken) {
      publish.queryAuthToken();
    }
    useSubscribe((message) => {
      if (message.type === 'EVENT:AUTH_TOKEN') {
        setHybridToken(message.payload.token);
        setHybridTokenType(message.payload.tokenType);
      }
    });

    return { hybridToken, hybridTokenType };
  },
);

export { useHybridToken };
