import styled from '@emotion/styled/macro';
import { Metric } from 'components/molecules/Metric';
import { DashboardMetric } from 'providers/Metrics';
import { Carousel } from 'components/molecules/Carousel';

type props = {
  metrics: DashboardMetric;
};

const MetricsGrid = ({ metrics }: props) => {
  return (
    <Container>
      <ShowCarousel>
        <Carousel>
          {metrics.map((metric, index) => {
            return (
              <Content id={index.toString()} key={metric.order || index}>
                <Metric
                  icon={metric.icon}
                  label={metric.label}
                  href={metric.href}
                  value={metric.value}
                />
              </Content>
            );
          })}
        </Carousel>
      </ShowCarousel>
      <InnerContainer>
        {metrics.map((metric, index) => {
          return (
            <Content
              id={index.toString()}
              key={(metric.order || index) + 'inner'}>
              <Metric
                icon={metric.icon}
                label={metric.label}
                href={metric.href}
                value={metric.value}
              />
            </Content>
          );
        })}
      </InnerContainer>
    </Container>
  );
};

const InnerContainer = styled.div`
  max-width: 1194px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 16px;
  box-sizing: border-box;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;

  &:first {
    background-color: pink;
  }

  @media (max-width: 880px) {
    display: none;
  }
`;

const Container = styled.div`
  padding-bottom: 280px;
  @media (max-width: 880px) {
    padding-bottom: 0px;
  }
`;

const Content = styled.div`
  display: flex;
  aspect-ratio: 1/1;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 28%;
  scroll-snap-align: center;
`;

const ShowCarousel = styled.div`
  display: none;
  @media (max-width: 880px) {
    display: block;
  }
`;

export { MetricsGrid };
