import { FullStory, init, isInitialized } from '@fullstory/browser';
import { Environment, getEnvironment } from '@sunrun/sales-experience-shared';
import { logger } from 'providers/logger';

const ORG_ID: Record<Environment, string> = {
  production: 'o-1ED2V5-na1',
  staging: 'o-1ED2V5-na1',
  relcert: 'o-1ED2V5-na1',
  development: 'disabled',
};

const initFullstory = () => {
  const env = getEnvironment();
  if (env === 'production' || env === 'staging') {
    init(
      {
        orgId: ORG_ID[env],
        recordCrossDomainIFrames: true,
      },
      ({ sessionUrl }) => {
        logger.info(`Fullstory Session Dashboard: ${sessionUrl}`);
      },
    );
  }
};

const sendTrackingEvent = (
  eventName: string,
  properties: { [key: string]: any },
) => {
  if (isInitialized()) {
    FullStory('trackEvent', { name: eventName, properties });
  }
};

export { initFullstory, sendTrackingEvent };
