import {
  AmplifyDelete,
  AmplifyGet,
  AmplifyPost,
} from '@sunrun/sales-experience-shared/amplify-api-wrapper';
import _uniqueId from 'lodash/uniqueId';
import groupBy from 'lodash/groupBy';
import {
  PipelineDisplayProspect,
  PipelineV2Stage,
} from '@sunrun/experience-ui-components';
import useSWR from 'swr';
import { subscribeToAsyncRequest } from '@sunrun/sales-experience-shared';
import { logger } from './logger';
import { useHybridToken } from './Auth';

type PipelineSalesRep = { id: string; name: string };

type PipelineProspect = {
  key: string; // We get dups where all data is the same this is a unique key for rendering
} & PipelineDisplayProspect;

type PipelineSalesRepsApiResponse = {
  pipelineSalesReps: Array<PipelineSalesRep>;
};

type PipelineManagementApiResponse = {
  prospects: Array<PipelineDisplayProspect>;
  salesReps: Array<PipelineSalesRep>;
};

type ProspectsByStage = {
  [stage in PipelineV2Stage]?: PipelineProspect[] | undefined;
};

type PipelineData = Omit<PipelineManagementApiResponse, 'prospects'> & {
  prospects: ProspectsByStage;
};

const getSalesReps = async (
  hybridToken: string,
): Promise<PipelineSalesRep[]> => {
  const response = (await AmplifyGet(
    'Get Pipeline',
    'SalesExpDashboardApi',
    `/sales-reps`,
    {
      headers: {
        Authorization: `Bearer ${hybridToken}`,
      },
    },
    logger,
  )) as PipelineSalesRepsApiResponse;

  return response.pipelineSalesReps.sort((a, b) =>
    a.name.localeCompare(b.name),
  );
};

const favoriteStorageKey = 'locallyUpdatedFavorites';

const getPipeline = async (
  hybridToken: string,
  salesReps: string[],
): Promise<PipelineData> => {
  const response = (await AmplifyGet(
    'Get Pipeline',
    'SalesExpDashboardApi',
    `/pipeline`,
    {
      headers: {
        Authorization: `Bearer ${hybridToken}`,
      },
      queryStringParameters: {
        salesReps: JSON.stringify(salesReps),
      },
    },
    logger,
  )) as PipelineManagementApiResponse;

  const storedUpdates = localStorage.getItem(favoriteStorageKey);
  const updatedMap = storedUpdates ? JSON.parse(storedUpdates) : {};
  Object.keys(updatedMap).forEach((key) => {
    const localUpdate = updatedMap[key];
    const minuteAge = Math.floor(
      (new Date().getTime() - localUpdate.cacheTime) / (1000 * 60),
    );
    if (minuteAge > 5) {
      updatedMap[key] = undefined;
    }
  });
  localStorage.setItem(favoriteStorageKey, JSON.stringify(updatedMap));
  const prospectsWithKeys = response.prospects.map((prospect) => {
    const prospectWithKey = {
      ...prospect,
      key: _uniqueId(),
    };
    if (updatedMap[prospect.prospectId]) {
      prospectWithKey.starredInPipeline =
        updatedMap[prospect.prospectId].starredInPipeline;
    }
    return prospectWithKey;
  });

  const prospects = groupBy(
    prospectsWithKeys,
    'pipelineStage',
  ) as ProspectsByStage;

  prospects.ARC = ([] as PipelineProspect[])
    .concat(prospects.ARC || [])
    .concat(prospects.Cancelled || [])
    .concat(prospects.Lost || []);

  return {
    ...response,
    prospects,
  };
};

const useSalesReps = () => {
  const { hybridToken } = useHybridToken();

  return useSWR(
    ['/sales-reps', hybridToken],
    ([, token]) => {
      if (!token) {
        return null;
      }
      return getSalesReps(token);
    },
    {
      revalidateOnFocus: false,
      errorRetryCount: 3,
    },
  );
};

const usePipeline = (salesReps: string[]) => {
  const { hybridToken } = useHybridToken();

  return useSWR(
    ['/pipeline', hybridToken, salesReps],
    ([, token, salesReps]) => {
      if (!token) {
        return null;
      }
      if (!salesReps || salesReps.length === 0) {
        return null;
      }
      return getPipeline(token, salesReps);
    },
    {
      revalidateOnFocus: false,
      errorRetryCount: 3,
    },
  );
};

const updateFavorite = async (
  hybridToken: string,
  pipelineProspect: PipelineProspect,
  starredInPipeline: boolean,
) => {
  const response = await AmplifyPost<{ asyncRequestId: string }>(
    'Update Favorite',
    'SalesExpDashboardApi',
    `/favorite/${pipelineProspect.prospectId}`,
    {
      headers: {
        Authorization: `Bearer ${hybridToken}`,
      },
      body: {
        prospectId: pipelineProspect.prospectId,
        starredInPipeline,
      },
    },
    logger,
  );

  await subscribeToAsyncRequest(hybridToken, response.asyncRequestId).then(
    () => {
      const storedUpdates = localStorage.getItem(favoriteStorageKey);
      const updatedMap = storedUpdates ? JSON.parse(storedUpdates) : {};
      updatedMap[pipelineProspect.prospectId] = {
        starredInPipeline,
        cacheTime: new Date().getTime(),
      };
      localStorage.setItem(favoriteStorageKey, JSON.stringify(updatedMap));
    },
  );
};

const deletePipelineCache = async (hybridToken: string, salesRepId: string) => {
  await AmplifyDelete(
    'Delete Pipeline Cache',
    'SalesExpDashboardApi',
    '/pipeline-cache',
    {
      headers: {
        Authorization: `Bearer ${hybridToken}`,
      },
      queryStringParameters: {
        salesReps: salesRepId,
      },
    },
    logger,
  );
};

export type { PipelineProspect, PipelineSalesRep, ProspectsByStage };
export { deletePipelineCache, usePipeline, useSalesReps, updateFavorite };
