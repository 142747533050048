import { useRef } from 'react';
import styled from '@emotion/styled/macro';
import findLastIndex from 'lodash/findLastIndex';
import { useIsInViewport } from 'helpers/useIsInViewport';

type props = {
  children: React.ReactNode[];
};

const Carousel = ({ children }: props) => {
  const refs: React.MutableRefObject<null>[] = [];
  const activeSlides: any = [];
  for (let i = 0; i < children.length; i++) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    refs[i] = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    activeSlides[i] = useIsInViewport(refs[i], i);
  }

  const getActiveSlide = () => {
    if (activeSlides[0]) return 0;
    if (activeSlides[activeSlides.length - 1]) return activeSlides.length - 1;
    const start = activeSlides.findIndex((i: boolean) => i);
    const end = findLastIndex(activeSlides, (i: boolean) => i);
    return start + (end - start);
  };
  const activeSlide = getActiveSlide();

  return (
    <Container aria-label="carousel">
      <Content>
        {children.map((child, index) => {
          return (
            <span key={index} ref={refs[index]}>
              {child}
            </span>
          );
        })}
      </Content>
      <Dots aria-label="carousel scroll">
        {children.map((child, index) => {
          return (
            <div
              key={index + 'dot'}
              className={activeSlide === index ? 'active' : ''}
              onClick={() =>
                (refs[index] as any).current.scrollIntoView({
                  behavior: 'smooth',
                  block: 'nearest',
                  inline: 'center',
                })
              }></div>
          );
        })}
      </Dots>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
`;
const Content = styled.div`
  display: flex;
  flex-wrap: nowrap;
  scroll-behavior: smooth;
  box-sizing: border-box;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  gap: 16px;
  padding: 32px 16px 32px 16px;
`;
const Dots = styled.aside`
  text-align: center;
  display: flex;
  height: 12px;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  & div {
    background-color: #6f6d68;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 180px;
    margin-left: 8px;
    margin-left: 8px;
    border: 0px solid #001c3a;
    transition: 0.3s;
  }
  & div.active {
    background-color: #001c3a;
    border: 1px solid #001c3a;
  }
`;

export { Carousel };
