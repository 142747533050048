import styled from '@emotion/styled/macro';
import {
  Button,
  Checkbox,
  SalesToolsWarning,
  MultiSelectDropdown,
  Select,
  SlidingDrawer,
  SvgIcon,
  SvgNames,
  TextField,
  Typography,
  tokens,
} from '@sunrun/experience-ui-components';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { PipelineSalesRep } from 'providers/Pipeline';
import { useUserProfile } from 'providers/UserProfile';

type PipelineControls = {
  searchTerm: string;
  sort: 'default' | 'newest' | 'oldest';
  salesReps: string[];
  includeSalesRepAction: boolean;
  includeUrgent: boolean;
  includedStarred: boolean;
};

const PipelineHeader = ({
  salesReps,
  appliedControls,
  onApplyControl,
}: {
  salesReps?: PipelineSalesRep[];
  appliedControls?: PipelineControls;
  onApplyControl: React.Dispatch<React.SetStateAction<PipelineControls>>;
}) => {
  const userProfile = useUserProfile();
  const [showSearch, setShowSearch] = useState(false);
  const [controls, setControls] = useState({
    searchTerm: '',
    sort: 'default',
    salesReps: [],
    includeSalesRepAction: false,
    includeUrgent: false,
    includedStarred: false,
  } as PipelineControls);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const timeout = useRef<number>();
  const isMobile = useMediaQuery({
    maxWidth: '450px',
  });

  const salesRepOptions = useMemo(() => {
    if (!salesReps || !userProfile) {
      return [];
    }
    return [
      {
        id: userProfile.contactId,
        text: 'Me',
        checked:
          !!controls && controls.salesReps.includes(userProfile.contactId),
      },
      ...salesReps
        .filter((e) => e.id !== userProfile.contactId)
        .map((e) => ({
          id: e.id,
          text: e.name,
          checked: !!controls && controls.salesReps.includes(e.id),
        })),
    ];
  }, [controls, salesReps, userProfile]);

  const numberAppliedFilers = useMemo(() => {
    let count = 0;
    if (appliedControls?.salesReps && appliedControls.salesReps.length > 0) {
      count += 1;
    }
    if (appliedControls?.includeSalesRepAction) {
      count += 1;
    }
    if (appliedControls?.includeUrgent) {
      count += 1;
    }
    if (appliedControls?.includedStarred) {
      count += 1;
    }
    return count;
  }, [appliedControls]);

  const searchChanged = useCallback(
    (newSearch: string) => {
      const lowercaseSearch = newSearch.toLowerCase();
      setControls((current) => ({
        ...current,
        searchTerm: lowercaseSearch,
      }));
      if (timeout.current) {
        window.clearTimeout(timeout.current);
      }
      timeout.current = window.setTimeout(() => {
        onApplyControl((current) => ({
          ...current,
          searchTerm: lowercaseSearch,
        }));
      }, 500);
    },
    [onApplyControl],
  );

  useEffect(() => {
    const hideSearch = () => {
      setShowSearch(false);
    };
    if (showSearch && controls.searchTerm.length === 0) {
      window.addEventListener('click', hideSearch, { capture: true });
    }
    return () => {
      window.removeEventListener('click', hideSearch, { capture: true });
    };
  }, [showSearch, setShowSearch, controls.searchTerm]);

  const shouldHideWarning =
    !userProfile?.title?.toLocaleLowerCase().includes('athlete') &&
    userProfile?.splatRole !== 'Retail Advisor' &&
    userProfile?.sellsSunrun?.toLocaleLowerCase() === 'no'
      ? 'No'
      : 'Yes';

  return (
    <PipelineHeaderContainer>
      <PipelineHeaderContent>
        <SalesToolsWarning sellsSunrun={shouldHideWarning} />
        <PipelineHeaderContentContainer>
          <Typography component="h1" size="large">
            {(!isMobile || !showSearch) && 'Pipeline'}
          </Typography>
          <RightArea>
            {!showSearch && (
              <SearchButton
                color="tertiary"
                iconMiddle={<SvgIcon name={SvgNames.Search}></SvgIcon>}
                onClick={() => setShowSearch(true)}
              />
            )}
            {showSearch && (
              <TextField
                style={{ height: '36px' }}
                autoFocus
                label=""
                placeholder="Search"
                data-testid="pipeline-search-input"
                value={controls.searchTerm}
                onChange={(event) => searchChanged(event.target.value)}
              />
            )}
            <FiltersButton
              color="secondary"
              size="sm"
              iconLeft={
                <SvgIcon
                  name={SvgNames.Filters}
                  width={24}
                  height={24}></SvgIcon>
              }
              iconRight={
                numberAppliedFilers > 0 && (
                  <AppliedFilterCount>{numberAppliedFilers}</AppliedFilterCount>
                )
              }
              onClick={() => {
                if (appliedControls) {
                  setControls(appliedControls);
                }
                setFiltersOpen(true);
              }}>
              Filters
            </FiltersButton>
          </RightArea>
        </PipelineHeaderContentContainer>
      </PipelineHeaderContent>
      <SlidingDrawer
        isOpen={filtersOpen}
        onClose={() => setFiltersOpen(false)}
        closeOnEscape={true}>
        <FilterContainer>
          <FilterHeader>
            <Typography component="h1" size="large">
              Filters
            </Typography>
            <Button
              color="tertiary"
              size="sm"
              iconMiddle={
                <SvgIcon
                  name={SvgNames.Close}
                  width={24}
                  height={24}
                  color={tokens.BLACK}></SvgIcon>
              }
              onClick={() => setFiltersOpen(false)}></Button>
          </FilterHeader>
          <FilterBody>
            <Typography>Sort by</Typography>
            <Select
              options={[
                {
                  label: 'Default',
                  value: 'default',
                },
                {
                  label: 'Recently Updated',
                  value: 'newest',
                },
                {
                  label: 'Oldest',
                  value: 'oldest',
                },
              ]}
              value={controls.sort}
              onChange={(e) =>
                setControls(
                  (current) =>
                    ({ ...current, sort: e.target.value } as PipelineControls),
                )
              }></Select>
            {salesRepOptions.length > 2 && (
              <>
                <MultiSelectDropdown
                  testId="sales-rep"
                  label="Sales rep"
                  options={salesRepOptions}
                  allOptionText="All Sales Reps"
                  onChange={(updatedValues) => {
                    let selectedOptions = updatedValues
                      .filter((e) => e.checked)
                      .map((e) => e.id);
                    setControls(
                      (current) =>
                        ({
                          ...current,
                          salesReps: selectedOptions,
                        } as PipelineControls),
                    );
                  }}></MultiSelectDropdown>
              </>
            )}
            <TypeLabel>
              <Typography>Type of sale</Typography>
            </TypeLabel>
            <TypeOption>
              <Checkbox
                label="Sales rep action"
                checked={controls.includeSalesRepAction}
                onChange={(e) =>
                  setControls((current) => ({
                    ...current,
                    includeSalesRepAction: e.target.checked,
                  }))
                }></Checkbox>
            </TypeOption>
            <TypeOption>
              <Checkbox
                label="Urgent"
                checked={controls.includeUrgent}
                onChange={(e) =>
                  setControls((current) => ({
                    ...current,
                    includeUrgent: e.target.checked,
                  }))
                }></Checkbox>
            </TypeOption>
            <TypeOption>
              <Checkbox
                label="Starred"
                checked={controls.includedStarred}
                onChange={(e) =>
                  setControls((current) => ({
                    ...current,
                    includedStarred: e.target.checked,
                  }))
                }></Checkbox>
            </TypeOption>
          </FilterBody>
          <FilterFooter>
            <Button color="secondary" onClick={() => setFiltersOpen(false)}>
              Close
            </Button>
            <Button
              color="primary"
              onClick={() => {
                onApplyControl(controls);
                setFiltersOpen(false);
              }}>
              Apply
            </Button>
          </FilterFooter>
        </FilterContainer>
      </SlidingDrawer>
    </PipelineHeaderContainer>
  );
};

const PipelineHeaderContainer = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: row;
`;

const PipelineHeaderContent = styled.div`
  position: sticky;
  left: 1.5rem;
  width: calc(100vw - 4rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const PipelineHeaderContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RightArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`;

const FiltersButton = styled(Button)`
  &.sr-button-sm.sr-button-secondary {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  > svg {
    margin: 0;
  }
`;

const AppliedFilterCount = styled.div`
  border-radius: 100px;
  padding: 0 0.5rem;
  background-color: ${tokens.BRAND_HERO_BLUE};
  color: ${tokens.WHITE};
  margin-left: 0.25rem;
`;

const SearchButton = styled(Button)`
  padding: 0;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const FilterHeader = styled.div`
  padding: 1rem;
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const FilterBody = styled.div`
  padding: 1rem;
  flex: 1 0 auto;
`;

const TypeLabel = styled.div`
  margin-top: 1rem;
`;

const TypeOption = styled.div`
  margin-top: 0.5rem;
`;

const FilterFooter = styled.div`
  padding: 1rem;
  flex: 0 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
`;

export type { PipelineControls };
export { PipelineHeader };
