import styled from '@emotion/styled/macro';
import {
  ARCSubStatus,
  Button,
  Card,
  CardHeader,
  CheckoutSubStatus,
  CityInspectionSubStatus,
  CityPermittingSubStatus,
  DesignApprovalSubStatus,
  HomeReviewSubStatus,
  HqsStatus,
  InstallPrepSubStatus,
  InstallationSubStatus,
  NewCustomerSubStatus,
  OfferCreatedSubStatus,
  PipelineUrgentActionBanner,
  PowerOnSubStatus,
  SvgIcon,
  SvgNames,
  Typography,
  tokens,
} from '@sunrun/experience-ui-components';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  FIELD_SALES,
  FUSION_CLOSER,
  FUSION_SETTER,
  getEnvironment,
  getSfdcEnv,
  INSIDE_SALES,
} from '@sunrun/sales-experience-shared';
import { useFeatureFlagString } from '@sunrun/sales-experience-shared/react-feature-flags';
import { createPortal } from 'react-dom';
import { css } from '@emotion/react';
import {
  deletePipelineCache,
  PipelineProspect,
  updateFavorite,
} from 'providers/Pipeline';
import { useUserProfile } from 'providers/UserProfile';
import { useHybridToken } from 'providers/Auth';
import { formatCurrency } from 'helpers/formatHelpers';
import { getHQS } from 'providers/HQS';

const PipelineCard = memo(
  ({
    pipelineProspect,
    includeSalesRep,
    useLeftMenu,
    allowJobCode,
  }: {
    pipelineProspect: PipelineProspect;
    includeSalesRep: boolean;
    useLeftMenu: boolean;
    allowJobCode: boolean;
  }) => {
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);
    const [expanded, setExpanded] = useState(false);
    const [isFavoriteSaving, setIsFavoriteSaving] = useState(false);
    const [hqsStatus, setHqsStatus] = useState<HqsStatus>(
      pipelineProspect.pipelineStage === 'New Customer' ||
        pipelineProspect.pipelineStage === 'Offer Created'
        ? 'LOADING'
        : 'INELIGIBLE',
    );

    const userProfile = useUserProfile();
    const { hybridToken, hybridTokenType } = useHybridToken();

    const configuredPartnerSfdcUrl = useFeatureFlagString('sfdc-partner-url');
    const configuredSfdcUrl = useFeatureFlagString('sfdc-url');

    const {
      viewInPartnerPortal,
      salesforceUrl,
      canUseAppointments,
      useJobCode,
      isOwner,
      useFusionCommission,
      projectUrl,
    } = useMemo(() => {
      if (!userProfile) return {};

      const isOwner = pipelineProspect.salesRepId === userProfile.contactId;

      let viewInPartnerPortal = false;
      let salesforceUrl;
      let projectUrl = undefined;
      const env = getEnvironment();
      switch (getSfdcEnv(userProfile)) {
        case 'Community': {
          const baseUrl =
            configuredPartnerSfdcUrl ??
            (env === 'production'
              ? 'https://sunrun.my.site.com'
              : 'https://sunrun--majstg.sandbox.my.site.com');
          const sfdcBaseUrl = `${baseUrl}/partners`;
          salesforceUrl = `${sfdcBaseUrl}/s/partnerOpp?id=${pipelineProspect.opportunityId}`;
          viewInPartnerPortal = true;
          break;
        }
        case 'D2HCommunity': {
          const baseUrl =
            configuredPartnerSfdcUrl ??
            (env === 'production'
              ? 'https://sunrun.my.site.com'
              : 'https://sunrun--majstg.sandbox.my.site.com');
          const sfdcBaseUrl = `${baseUrl}/d2hpartnercommunity`;
          salesforceUrl = `${sfdcBaseUrl}/PartnerOpportunityCreate?id=${pipelineProspect.opportunityId}`;
          if (pipelineProspect.projectId) {
            projectUrl = `${sfdcBaseUrl}/WFProjectRedirectPage?id=${
              pipelineProspect.projectId
            }${isOwner ? '' : '&mgrView=1'}`;
          }
          break;
        }
        case 'SFDC':
        case 'None':
        default: {
          const sfdcBaseUrl =
            configuredSfdcUrl ??
            (env === 'production'
              ? 'https://sunrun.my.salesforce.com'
              : 'https://sunrun--majstg.sandbox.my.salesforce.com');
          salesforceUrl = `${sfdcBaseUrl}/${pipelineProspect.opportunityId}`;
          if (pipelineProspect.projectId) {
            projectUrl = `${sfdcBaseUrl}/${pipelineProspect.projectId}`;
          }
          break;
        }
      }

      const canUseAppointments =
        userProfile?.splatRole === INSIDE_SALES ||
        userProfile?.splatRole === FIELD_SALES ||
        userProfile?.splatRole === FUSION_CLOSER ||
        userProfile?.splatRole === FUSION_SETTER;

      const useJobCode = pipelineProspect.jobCode && allowJobCode;

      const useFusionCommission = userProfile.isFusion;
      return {
        viewInPartnerPortal,
        salesforceUrl,
        canUseAppointments,
        useJobCode,
        isOwner,
        useFusionCommission,
        projectUrl,
      };
    }, [
      allowJobCode,
      configuredPartnerSfdcUrl,
      configuredSfdcUrl,
      pipelineProspect,
      userProfile,
    ]);

    const daysAgoText = useMemo(() => {
      const daysAgo = Math.floor(
        (new Date().getTime() -
          Date.parse(pipelineProspect.statusDate + '-0000')) /
          1000 /
          60 /
          60 /
          24,
      );
      if (daysAgo <= 1) {
        return 'Updated today';
      }
      if (daysAgo === 1) {
        return 'Updated yesterday';
      }
      return `Updated ${daysAgo} days ago`;
    }, [pipelineProspect.statusDate]);

    const env = getEnvironment();
    const {
      splatBaseUrl,
      oneBaseUrl,
      salesforceBaseUrl,
      sfPartnerBaseUrl,
      sfdcEnv,
    } = useMemo(() => {
      return {
        splatBaseUrl:
          env === 'production'
            ? 'https://go.sunrun.com'
            : 'https://majstg-go.sunrun.com',
        oneBaseUrl:
          env === 'production'
            ? 'https://sunrunone.com'
            : 'https://staging.sunrunone.com',
        salesforceBaseUrl:
          env === 'production'
            ? 'https://sunrun.my.salesforce.com'
            : 'https://sunrun--majstg.sandbox.my.salesforce.com',
        sfPartnerBaseUrl:
          env === 'production'
            ? 'https://sunrun.my.site.com'
            : 'https://sunrun--majstg.sandbox.my.site.com',
        sfdcEnv: (userProfile && getSfdcEnv(userProfile)) || 'SFDC',
      };
    }, [env, userProfile]);

    const starClick = useCallback(() => {
      setIsFavoriteSaving(true);
      updateFavorite(
        hybridToken,
        pipelineProspect,
        !pipelineProspect.starredInPipeline,
      )
        .then(() =>
          pipelineProspect.salesRepId
            ? deletePipelineCache(hybridToken, pipelineProspect.salesRepId)
            : undefined,
        )
        .then(() => {
          pipelineProspect.starredInPipeline =
            !pipelineProspect.starredInPipeline;
        })
        .catch(() => {
          // Do nothing
        })
        .finally(() => setIsFavoriteSaving(false));
    }, [hybridToken, pipelineProspect]);

    const openOfferPresentation = useCallback(() => {
      const { username, email, splatRole } = userProfile ?? {};
      const userName = encodeURIComponent(username ?? '');
      const userEmail = encodeURIComponent(email ?? '');
      const userRole = encodeURIComponent(splatRole ?? '');

      const redirectUrl = encodeURIComponent(
        `/go-solar/${pipelineProspect.prospectId}?prospectId=${pipelineProspect.prospectId}&username=${userName}&email=${userEmail}&role=${userRole}`,
      );
      const url = `${splatBaseUrl}/login?token=${hybridToken}&tokenType=hybrid-${hybridTokenType}&redirect=${redirectUrl}`;
      window.open(url, '_blank');
    }, [
      hybridToken,
      hybridTokenType,
      pipelineProspect,
      splatBaseUrl,
      userProfile,
    ]);

    const navigateToCustomerProfile = useCallback(() => {
      window.open(
        `${oneBaseUrl}/customers/customer-profile/${pipelineProspect.prospectId}`,
        '_blank',
      );
    }, [oneBaseUrl, pipelineProspect.prospectId]);

    const emailCustomer = useCallback(() => {
      window.open(`mailto:${pipelineProspect.email}`, '_blank');
    }, [pipelineProspect]);

    const viewInSalesforce = useCallback(() => {
      window.open(salesforceUrl, '_blank');
    }, [salesforceUrl]);

    const viewProject = useCallback(() => {
      window.open(projectUrl, '_blank');
    }, [projectUrl]);

    const copyCustomerInformation = useCallback(() => {
      navigator.clipboard.writeText(
        `Customer: ${pipelineProspect.firstName} ${
          pipelineProspect.lastName
        }\nAddress: ${pipelineProspect.address}\n${
          useJobCode
            ? 'Job code: ' + pipelineProspect.jobCode
            : 'Prospect: ' + pipelineProspect.prospectId
        }`,
      );
      setIsOpen(false);
    }, [pipelineProspect, useJobCode]);

    const pipelineStatus = useMemo(() => {
      switch (pipelineProspect.pipelineStage) {
        case 'New Customer':
          return (
            <NewCustomerSubStatus
              canUseAppointments={canUseAppointments || false}
              hqsStatus={hqsStatus}
              pipelineProspect={pipelineProspect}
              splatBaseUrl={splatBaseUrl}
              oneBaseUrl={oneBaseUrl}
              salesforceBaseUrl={salesforceBaseUrl}
              sfPartnerBaseUrl={sfPartnerBaseUrl}
              sfdcEnv={sfdcEnv}
              isDisabled={!isOwner}></NewCustomerSubStatus>
          );
        case 'Offer Created':
          return (
            <OfferCreatedSubStatus
              canUseAppointments={canUseAppointments || false}
              hqsStatus={hqsStatus}
              pipelineProspect={pipelineProspect}
              splatBaseUrl={splatBaseUrl}
              oneBaseUrl={oneBaseUrl}
              isDisabled={!isOwner}></OfferCreatedSubStatus>
          );
        case 'Checkout':
          return (
            <CheckoutSubStatus
              pipelineProspect={pipelineProspect}
              splatBaseUrl={splatBaseUrl}
              salesforceBaseUrl={salesforceBaseUrl}
              sfPartnerBaseUrl={sfPartnerBaseUrl}
              sfdcEnv={sfdcEnv}
              isDisabled={!isOwner}></CheckoutSubStatus>
          );
        case 'Home Review':
          return (
            <HomeReviewSubStatus
              pipelineProspect={pipelineProspect}
              splatBaseUrl={splatBaseUrl}
              salesforceBaseUrl={salesforceBaseUrl}
              sfPartnerBaseUrl={sfPartnerBaseUrl}
              sfdcEnv={sfdcEnv}
              isDisabled={!isOwner}></HomeReviewSubStatus>
          );
        case 'Design Approval':
          return (
            <DesignApprovalSubStatus
              pipelineProspect={pipelineProspect}
              splatBaseUrl={splatBaseUrl}
              salesforceBaseUrl={salesforceBaseUrl}
              sfPartnerBaseUrl={sfPartnerBaseUrl}
              sfdcEnv={sfdcEnv}
              isDisabled={!isOwner}></DesignApprovalSubStatus>
          );
        case 'City Permitting':
          return (
            <CityPermittingSubStatus
              pipelineProspect={pipelineProspect}
              salesforceBaseUrl={salesforceBaseUrl}
              sfPartnerBaseUrl={sfPartnerBaseUrl}
              sfdcEnv={sfdcEnv}
              isDisabled={!isOwner}></CityPermittingSubStatus>
          );
        case 'Install Prep':
          return (
            <InstallPrepSubStatus
              pipelineProspect={pipelineProspect}
              salesforceBaseUrl={salesforceBaseUrl}
              sfPartnerBaseUrl={sfPartnerBaseUrl}
              sfdcEnv={sfdcEnv}
              isDisabled={!isOwner}></InstallPrepSubStatus>
          );
        case 'Installation':
          return (
            <InstallationSubStatus
              pipelineProspect={pipelineProspect}
              salesforceBaseUrl={salesforceBaseUrl}
              sfPartnerBaseUrl={sfPartnerBaseUrl}
              sfdcEnv={sfdcEnv}
              isDisabled={!isOwner}></InstallationSubStatus>
          );
        case 'City Inspection':
          return (
            <CityInspectionSubStatus
              pipelineProspect={pipelineProspect}
              salesforceBaseUrl={salesforceBaseUrl}
              sfPartnerBaseUrl={sfPartnerBaseUrl}
              sfdcEnv={sfdcEnv}
              isDisabled={!isOwner}></CityInspectionSubStatus>
          );
        case 'Power On':
          return (
            <PowerOnSubStatus
              pipelineProspect={pipelineProspect}
              salesforceBaseUrl={salesforceBaseUrl}
              sfPartnerBaseUrl={sfPartnerBaseUrl}
              sfdcEnv={sfdcEnv}
              isDisabled={!isOwner}></PowerOnSubStatus>
          );
        case 'ARC':
          return (
            <ARCSubStatus
              pipelineProspect={pipelineProspect}
              salesforceBaseUrl={salesforceBaseUrl}
              sfPartnerBaseUrl={sfPartnerBaseUrl}
              sfdcEnv={sfdcEnv}
              isDisabled={!isOwner}></ARCSubStatus>
          );
        case 'UNKNOWN':
        default:
          return <div></div>;
      }
    }, [
      canUseAppointments,
      hqsStatus,
      isOwner,
      oneBaseUrl,
      pipelineProspect,
      salesforceBaseUrl,
      sfPartnerBaseUrl,
      sfdcEnv,
      splatBaseUrl,
    ]);

    useEffect(() => {
      if (isOpen) {
        const cleanMenu = () => {
          setIsOpen(false);
        };
        const onKeyUp = (event: KeyboardEvent) => {
          if (event.key === 'Escape') cleanMenu();
        };
        const rootElement = document.getElementById('root');
        if (!rootElement) {
          return;
        }
        rootElement.addEventListener('click', cleanMenu, { capture: true });
        rootElement.addEventListener('scroll', cleanMenu, { capture: true });
        rootElement.addEventListener('keyup', onKeyUp);
        return () => {
          rootElement.removeEventListener('click', cleanMenu, {
            capture: true,
          });
          rootElement.removeEventListener('scroll', cleanMenu, {
            capture: true,
          });
          rootElement.removeEventListener('keyup', onKeyUp);
        };
      }
    }, [isOpen, setIsOpen]);

    useEffect(() => {
      if (expanded && hqsStatus === 'LOADING') {
        getHQS(hybridToken, pipelineProspect.prospectId)
          .then((result) => setHqsStatus(result))
          .catch(() => {
            // Do nothing.
          });
      }
    }, [hqsStatus, hybridToken, expanded, pipelineProspect.prospectId]);

    return (
      <PipelineStyledCard key={pipelineProspect.key}>
        <PipelineCardHeader>
          <TitleContainer>
            {pipelineProspect.firstName} {pipelineProspect.lastName}
          </TitleContainer>
          <div>
            <MenuButton
              color="tertiary"
              onClick={starClick}
              disabled={isFavoriteSaving}
              iconMiddle={
                <SvgIcon
                  name={
                    isFavoriteSaving
                      ? SvgNames.Loading
                      : pipelineProspect.starredInPipeline
                      ? SvgNames.StarFilled
                      : SvgNames.Star
                  }
                  width={24}
                  height={24}
                  color="black"
                />
              }></MenuButton>
          </div>
        </PipelineCardHeader>
        <StyledPipelineUrgentActionBanner
          pipelineProspect={pipelineProspect}
          oneBaseUrl={oneBaseUrl}
          salesforceBaseUrl={salesforceBaseUrl}
          sfdcEnv={sfdcEnv}
          sfPartnerBaseUrl={sfPartnerBaseUrl}
          splatBaseUrl={splatBaseUrl}></StyledPipelineUrgentActionBanner>
        <PipelineCardBodyContainer>
          <PipelineCardBody>
            <div>{pipelineProspect.address}</div>
            <div ref={menuRef}>
              <MenuButton
                color="tertiary"
                onClick={() => setIsOpen((current) => !current)}
                iconMiddle={
                  <SvgIcon
                    name={SvgNames.VerticalDotsSvg}
                    width={21}
                    height={21}
                    viewBox="0 0 21 21"
                  />
                }></MenuButton>
              {isOpen &&
                createPortal(
                  <Menu element={menuRef.current!} leftMenu={useLeftMenu}>
                    {isOwner && (
                      <>
                        <Button
                          color="tertiary"
                          onClick={navigateToCustomerProfile}>
                          View customer profile
                        </Button>
                        <Button
                          color="tertiary"
                          onClick={openOfferPresentation}>
                          View offer presentation
                        </Button>
                        <Button color="tertiary" onClick={viewInSalesforce}>
                          View opportunity in{' '}
                          {viewInPartnerPortal
                            ? 'Partner Portal'
                            : 'Salesforce'}
                        </Button>
                      </>
                    )}
                    {projectUrl && (
                      <Button color="tertiary" onClick={viewProject}>
                        View project in Salesforce
                      </Button>
                    )}
                    <Button color="tertiary" onClick={copyCustomerInformation}>
                      Copy customer information
                    </Button>
                  </Menu>,
                  document.body,
                )}
            </div>
          </PipelineCardBody>
          {includeSalesRep && (
            <SalesRepInfo>
              <Typography>Sales rep</Typography>
              <Typography>{pipelineProspect.salesRepName}</Typography>
            </SalesRepInfo>
          )}
          <PipelineStatus className={expanded ? '' : 'single'}>
            {pipelineStatus}
            <div>
              <Button
                color="tertiary"
                size="sm"
                onClick={() => setExpanded((current) => !current)}
                iconMiddle={
                  <SvgIcon
                    name={
                      expanded ? SvgNames.ChevronUp : SvgNames.ChevronDown
                    }></SvgIcon>
                }></Button>
            </div>
          </PipelineStatus>
          <ProspectInfo className={expanded ? 'visible' : ''}>
            <ProspectInfoItem>
              <Typography>Phone Number</Typography>
              <a href={`tel:${pipelineProspect.phone}`}>
                {pipelineProspect.phone}
              </a>
            </ProspectInfoItem>
            <ProspectInfoItem>
              <Typography>Email</Typography>
              <Button color="link" onClick={emailCustomer}>
                {pipelineProspect.email}
              </Button>
            </ProspectInfoItem>
            {pipelineProspect.pipelineStage === 'Design Approval' && (
              <ProspectInfoItem>
                <Typography>Est. net commission</Typography>
                <Typography>
                  {formatCurrency(
                    useFusionCommission
                      ? pipelineProspect.fusionCommission
                      : pipelineProspect.commission,
                    0,
                  )}
                </Typography>
              </ProspectInfoItem>
            )}
            {!useJobCode && (
              <ProspectInfoItem>
                <Typography>Prospect ID</Typography>
                <Typography>{pipelineProspect.prospectId}</Typography>
              </ProspectInfoItem>
            )}
            {useJobCode && (
              <ProspectInfoItem>
                <Typography>Job code</Typography>
                <Typography>{pipelineProspect.jobCode}</Typography>
              </ProspectInfoItem>
            )}
          </ProspectInfo>
          <Typography color={tokens.TINTS_OFF_WHITE_20}>
            {daysAgoText}
          </Typography>
        </PipelineCardBodyContainer>
      </PipelineStyledCard>
    );
  },
);

const PipelineStyledCard = styled(Card)`
  width: 303px;
  box-shadow: 0px 4px 10px 0px #0000001f;
`;

const PipelineCardHeader = styled(CardHeader)`
  padding: 12px;
  height: auto;
  background: ${tokens.TINTS_CHARGE_BLUE_80};
  justify-content: space-between;
`;

const TitleContainer = styled.div`
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const PipelineCardBodyContainer = styled.div`
  margin: 1rem !important;
`;

const StyledPipelineUrgentActionBanner = styled(PipelineUrgentActionBanner)`
  margin: 0 !important;
  padding: 0 !important;
  border-bottom: 1px solid ${tokens.TINTS_OFF_WHITE_40};

  > dialog {
    border: none;
    position: static !important;
  }
`;

const PipelineCardBody = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

const PipelineStatus = styled.div`
  margin-top: 1rem;
  border-top: 1px solid ${tokens.TINTS_OFF_WHITE_40};
  display: flex;
  justify-content: space-between;

  &.single .status-complete {
    display: none;
  }

  &.single .status-incomplete ~ .status-incomplete,
  &.single .status-incomplete ~ .status-loading {
    display: none;
  }
`;

const MenuButton = styled(Button)`
  padding: 0.25rem;
`;

const Menu = styled.div<{ element: HTMLDivElement; leftMenu: boolean }>`
  position: absolute;
  background: ${tokens.TINTS_OFF_WHITE_80};
  border: 1px solid ${tokens.TINTS_OFF_WHITE_40};
  border-radius: 10px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 230px;

  ${(props) => {
    const rect = props.element.getBoundingClientRect();
    if (props.leftMenu) {
      return css`
        top: ${rect.top + 'px'};
        left: ${rect.left - 230 + 'px'};
      `;
    }
    return css`
      top: ${rect.top + 'px'};
      left: ${rect.left + rect.width + 'px'};
    `;
  }}

  > button {
    padding: 0.5rem;
    text-wrap: nowrap;

    > span {
      margin: 0 !important;
    }
  }

  > * + * {
    border-top: 1px solid ${tokens.TINTS_OFF_WHITE_40};
    border-radius: 0;
  }
`;

const SalesRepInfo = styled.div`
  margin-top: 1rem;
  border-top: 1px solid ${tokens.TINTS_OFF_WHITE_40};
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

const ProspectInfo = styled.div`
  display: none;

  &.visible {
    display: block;
  }
`;

const ProspectInfoItem = styled.div`
  border-top: 1px solid ${tokens.TINTS_OFF_WHITE_40};
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;

  > button {
    overflow: hidden;

    > span {
      text-wrap: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  > a {
    color: black;
  }
`;

export { PipelineCard };
