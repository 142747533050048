import { UserProfile } from '@sunrun/sales-experience-shared';
import { AmplifyGet } from '@sunrun/sales-experience-shared/amplify-api-wrapper';
import { logger } from 'providers/logger';

type getMetricsResponse = {
  metrics: {
    type:
      | 'NewLeads'
      | 'ContractsSigned'
      | 'CAPs'
      | 'PermitsApproved'
      | 'InstallCompleted'
      | 'NTP'
      | 'M1'
      | 'M3';
    value: number | null;
  }[];
  userProfile: UserProfile;
};

type DashboardMetric = {
  icon: string;
  label: string;
  href: string;
  value: null | number;
  order: number;
}[];

const getMetrics = async (
  authkey: string,
): Promise<{
  metrics: DashboardMetric;
  userProfile: UserProfile;
}> => {
  const metricsData = (await AmplifyGet(
    'Get Dashboard Metrics',
    'SalesExpDashboardApi',
    `/metrics`,
    {
      headers: {
        Authorization: `Bearer ${authkey}`,
      },
    },
    logger,
  )) as getMetricsResponse;
  // eslint-disable-next-line array-callback-return
  const metrics = metricsData.metrics.map((metric) => {
    switch (metric.type) {
      case 'NewLeads':
        return {
          icon: 'users',
          label: 'New Leads',
          href: 'google.com',
          value: metric.value,
          order: 1,
        };
      case 'ContractsSigned':
        return {
          icon: 'pen-tool',
          label: 'Contracts Signed',
          href: 'google.com',
          value: metric.value,
          order: 2,
        };
      case 'CAPs':
        return {
          icon: 'trending-up',
          label: 'CAPs',
          href: 'google.com',
          value: metric.value,
          order: 3,
        };
      case 'PermitsApproved':
        return {
          icon: 'check',
          label: 'Permits Approved',
          href: 'google.com',
          value: metric.value,
          order: 4,
        };
      case 'InstallCompleted':
        return {
          icon: 'user-check',
          label: 'Installs Completed',
          href: 'google.com',
          value: metric.value,
          order: 5,
        };
      case 'NTP':
        return {
          icon: 'check',
          label: 'Sunrun NTPs',
          href: 'google.com',
          value: metric.value,
          order: 6,
        };
      case 'M1':
        return {
          icon: 'check',
          label: 'M1',
          href: 'google.com',
          value: metric.value,
          order: 7,
        };
      case 'M3':
        return {
          icon: 'check',
          label: 'M3',
          href: 'google.com',
          value: metric.value,
          order: 8,
        };
    }
  });
  return {
    userProfile: metricsData.userProfile,
    metrics: [
      ...metrics.sort((m1, m2) => m1.order - m2.order),
      {
        icon: 'plus',
        label: 'View All',
        href: '/customers/pipeline',
        value: null,
        order: 9,
      },
    ],
  };
};

export { getMetrics };
export type { getMetricsResponse, DashboardMetric };
