import styled from '@emotion/styled/macro';
import { SvgIcon, SvgNames } from '@sunrun/experience-ui-components';
import { isInIframe } from '../../helpers/isInIframe';

type props = {
  value?: number | null;
  label: string;
  icon: string;
  href?: string;
  transparent?: boolean;
};

const Metric = ({ icon, label, href, value = null }: props) => {
  const isViewAll = icon === 'plus';
  const handleClick = () => {
    if (isInIframe()) {
      window.parent.postMessage(
        {
          type: 'COMMAND:CHANGE_URL',
          source: 'Dashboard',
          payload: { url: href },
        },
        '*',
      );
    }
  };

  return (
    <Container
      className={isViewAll ? 'transparent' : ''}
      aria-label={label}
      onClick={isViewAll ? handleClick : undefined}>
      {icon && (
        <SvgIcon height={24} width={24} name={icon as SvgNames} color="black" />
      )}
      {value !== null && <MetricValue>{value}</MetricValue>}
      {value === null && !isViewAll && (
        <MetricValuePlaceholder>{value}</MetricValuePlaceholder>
      )}
      <Label>{label}</Label>
    </Container>
  );
};

const Container = styled.div`
  aspect-ratio: 1/1;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  background: rgba(255, 254, 252, 0.8);
  box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  padding: 16px;
  text-decoration: none;
  outline: 0px;
  transition: 0.15s;
  &.transparent {
    background: rgba(255, 254, 252, 0);
    border: 1px solid #a3a09a;
    cursor: pointer;
  }
  /* &:focus-visible {
    box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.2);
    background: rgba(255, 254, 252, 1);
  }
  &:hover {
    box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.2);
    background: rgba(255, 254, 252, 1);
  } */

  min-width: 224px;
  min-height: 224px;
  max-width: 386px;
  max-height: 386px;
`;

const MetricValuePlaceholder = styled.div`
  content: '';
  width: 100px;
  height: 1em;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.1),
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0)
  );
  background-size: 400% 400%;
  animation: gradient 3s ease infinite;
  border-radius: 30px;
  @keyframes gradient {
    0% {
      background-position: 100% 0%;
    }
    100% {
      background-position: 0% 100%;
    }
  }
`;

const Label = styled.div`
  margin-top: auto;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #181e3a;
  @media (min-width: 1600px) {
    font-size: 20px;
    padding-bottom: 10px;
  }
`;

const MetricValue = styled.div`
  font-weight: 400;
  font-size: 60px;
  color: #181e3a;
  height: 145px;
  @media (min-width: 1600px) {
    font-size: 80px;
  }
  @media (max-width: 1055px) {
    height: 125px;
  }
`;

export { Metric };
