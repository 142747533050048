import styled from '@emotion/styled/macro';
import {
  Button,
  LoadingIndicator,
  PipelineV2Stage,
  Snackbar,
  tokens,
} from '@sunrun/experience-ui-components';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  ProspectsByStage,
  usePipeline,
  useSalesReps,
} from 'providers/Pipeline';
import {
  PipelineControls,
  PipelineHeader,
} from 'components/molecules/PipelineHeader';
import {
  PIPELINE_COLUMN_WIDTH,
  PipelineColumn,
} from 'components/organisms/PipelineColumn';
import { useUserProfile } from 'providers/UserProfile';

const PipelineUrgentItems = memo(
  ({
    pipelineData,
    viewUrgentItems,
  }: {
    pipelineData: ProspectsByStage | undefined;
    viewUrgentItems: () => void;
  }) => {
    const [urgentItems, setUrgentItems] = useState(0);
    useEffect(() => {
      if (!pipelineData) {
        return;
      }
      const urgentItems = Object.entries(pipelineData)
        .flatMap(([stage, prospects]) => prospects)
        .filter((e) => e.urgentAction);
      if (urgentItems.length > 0) {
        setUrgentItems(urgentItems.length);
      }
    }, [pipelineData]);

    return (
      <Snackbar
        open={urgentItems > 0}
        onClose={() => setUrgentItems(0)}
        message={`You have ${urgentItems} new urgent task${
          urgentItems > 1 ? 's' : ''
        } to solve.`}
        autoHideDuration={0}
        type="error"
        colorScheme="dark"
        action={
          <SnackbarButton
            size="sm"
            onClick={() => {
              setUrgentItems(0);
              viewUrgentItems();
            }}>
            Check tasks
          </SnackbarButton>
        }></Snackbar>
    );
  },
);

const Pipeline = () => {
  const {
    data: salesRepData,
    isLoading: isSalesRepLoading,
    error: salesRepError,
  } = useSalesReps();

  const [controls, setControls] = useState({
    searchTerm: '',
    sort: 'default',
    salesReps: [],
    includeSalesRepAction: false,
    includeUrgent: false,
    includedStarred: false,
  } as PipelineControls);

  const {
    data: pipelineData,
    isLoading: isPipelineLoading,
    error: pipelineError,
  } = usePipeline(controls.salesReps);

  const allowExpand = useMediaQuery({
    minWidth: `${PIPELINE_COLUMN_WIDTH * 2}px`,
  });

  const userProfile = useUserProfile();

  const hasSetInitialRep = useRef(false);

  const displayedData = useMemo(() => {
    if (!pipelineData) {
      return {};
    }
    const filteredSortedData = Object.fromEntries(
      Object.entries(pipelineData.prospects).map(([stage, prospects]) => {
        let filteredData;
        if (
          !controls ||
          (controls.searchTerm.length === 0 &&
            controls.salesReps.length === 0 &&
            !controls.includeSalesRepAction &&
            !controls.includeUrgent &&
            !controls.includedStarred)
        ) {
          filteredData = prospects;
        } else {
          filteredData = prospects.filter((pipelineProspect) => {
            if (
              controls.salesReps.length > 0 &&
              pipelineProspect.salesRepId &&
              !controls.salesReps.includes(pipelineProspect.salesRepId)
            ) {
              return false;
            }
            if (
              controls.includeSalesRepAction &&
              !pipelineProspect.salesRepAction
            ) {
              return false;
            }
            if (controls.includeUrgent && !pipelineProspect.urgentAction) {
              return false;
            }
            if (
              controls.includedStarred &&
              !pipelineProspect.starredInPipeline
            ) {
              return false;
            }
            const searchTerm = controls.searchTerm;
            if (searchTerm.length === 0) {
              return true;
            }
            const name =
              `${pipelineProspect.firstName} ${pipelineProspect.lastName}`.trim();
            return (
              (pipelineProspect.address &&
                pipelineProspect.address.toLowerCase().includes(searchTerm)) ||
              (pipelineProspect.email &&
                pipelineProspect.email.toLowerCase().includes(searchTerm)) ||
              name.toLowerCase().includes(searchTerm) ||
              (pipelineProspect.jobCode &&
                pipelineProspect.jobCode.toLowerCase().includes(searchTerm)) ||
              (pipelineProspect.phone &&
                pipelineProspect.phone.toLowerCase().includes(searchTerm)) ||
              (pipelineProspect.prospectId &&
                pipelineProspect.prospectId.toLowerCase().includes(searchTerm))
            );
          });
        }
        const sortedData = filteredData.sort((a, b) => {
          if (!controls || controls.sort === 'default') {
            if (a.salesRepAction && !b.salesRepAction) {
              return -1;
            }
            if (b.salesRepAction && !a.salesRepAction) {
              return 1;
            }
            if (a.starredInPipeline && !b.starredInPipeline) {
              return -1;
            }
            if (b.starredInPipeline && !a.starredInPipeline) {
              return 1;
            }
            return Date.parse(b.statusDate) - Date.parse(a.statusDate);
          }
          if (controls.sort === 'newest') {
            return Date.parse(b.statusDate) - Date.parse(a.statusDate);
          }
          if (controls.sort === 'oldest') {
            return Date.parse(a.statusDate) - Date.parse(b.statusDate);
          }
          return 0;
        });
        return [stage as PipelineV2Stage, sortedData];
      }),
    );
    return filteredSortedData;
  }, [pipelineData, controls]);

  const hasData = useMemo(() => {
    if (!displayedData) {
      return false;
    }

    const stageWithProspects = Object.entries(displayedData).find(
      ([stage, prospects]) => prospects.length > 0,
    );
    return stageWithProspects !== undefined;
  }, [displayedData]);

  const viewUrgentItems = useCallback(() => {
    setControls((current) => ({
      ...current,
      searchTerm: '',
      sort: 'default',
      includeSalesRepAction: false,
      includeUrgent: true,
      includedStarred: false,
    }));
  }, []);

  useEffect(() => {
    if (hasSetInitialRep.current || !userProfile) {
      return;
    }

    setControls(
      (current) =>
        ({
          ...current,
          salesReps: [userProfile.contactId],
        } as PipelineControls),
    );
    hasSetInitialRep.current = true;
  }, [userProfile]);

  if (isSalesRepLoading || isPipelineLoading) {
    return (
      <LoadingContainer>
        <LoadingIndicator color="black" />
      </LoadingContainer>
    );
  }

  if (salesRepError || pipelineError) {
    return <div>There was an error loading data</div>;
  }

  const orderedStages: (keyof ProspectsByStage)[] = [
    'New Customer',
    'Offer Created',
    'Checkout',
    'Home Review',
    'Design Approval',
    'City Permitting',
    'Install Prep',
    'Installation',
    'City Inspection',
    'Power On',
    'ARC',
    'UNKNOWN',
  ];

  return (
    <PipelinePage>
      <PipelineHeader
        appliedControls={controls}
        onApplyControl={setControls}
        salesReps={salesRepData ?? []}></PipelineHeader>
      {!hasData && (
        <NoDataMessage>
          No available data matches the applied filters!
        </NoDataMessage>
      )}
      {hasData && (
        <ColumnContainer>
          {orderedStages.map((stage, index) => (
            <PipelineColumn
              key={stage}
              stage={stage}
              data={displayedData[stage]}
              allowExpand={allowExpand}
              includeSalesRep={
                controls.salesReps.find((e) => e !== userProfile?.contactId) !==
                undefined
              }
              useLeftMenu={index === orderedStages.length - 1}></PipelineColumn>
          ))}
          <PipelineUrgentItems
            pipelineData={pipelineData?.prospects}
            viewUrgentItems={viewUrgentItems}
          />
        </ColumnContainer>
      )}
    </PipelinePage>
  );
};

const PipelinePage = styled.div`
  height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr;
  min-width: fit-content;
`;

const NoDataMessage = styled.div`
  padding: 1rem;
`;

const ColumnContainer = styled.div`
  height: 100%;
  overflow-y: scroll;
  margin: 0 1.5rem 1.5rem 1.5rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  scroll-snap-type: x mandatory;
`;

const LoadingContainer = styled.div`
  height: 100vh;
  align-content: center;
`;

const SnackbarButton = styled(Button)`
  color: black;
  background-color: ${tokens.ERROR_90};
`;

export { Pipeline };
