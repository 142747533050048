import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom';
import { ErrorNotFound } from 'components/pages/ErrorNotFound';
import { Overview } from 'components/pages/Overview';
import { Pipeline } from 'components/pages/Pipeline';

const routes: Array<RouteObject> = [
  {
    path: '/overview',
    element: <Overview />,
  },
  {
    path: '/pipeline',
    element: <Pipeline />,
  },
  {
    path: '/',
    element: <Navigate to="/overview" replace />,
  },
  {
    path: '/dashboard',
    element: <Navigate to="/overview" replace />,
  },
  {
    path: '*',
    element: <ErrorNotFound />,
  },
];

const router = createBrowserRouter(routes);

export { router };
