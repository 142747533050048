import { HqsStatus } from '@sunrun/experience-ui-components';
import { AmplifyGet } from '@sunrun/sales-experience-shared/amplify-api-wrapper';
import { logger } from './logger';

const getHQS = async (
  hybridToken: string,
  prospectId: string,
): Promise<HqsStatus> => {
  const response = await AmplifyGet<{
    isEligible: boolean;
    hasSubmitted: boolean;
  }>(
    'Get HQS',
    'SalesExpDashboardApi',
    `/hqs-eligibility/${prospectId}`,
    {
      headers: {
        Authorization: `Bearer ${hybridToken}`,
      },
    },
    logger,
  );

  return response.hasSubmitted
    ? 'COMPLETE'
    : response.isEligible
    ? 'ELIGIBLE'
    : 'INELIGIBLE';
};

export { getHQS };
